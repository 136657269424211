@layer components {
  .card {
    @apply rounded-8 p-1;

    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  }

  .overlay-gradient-border {
    @apply rounded-8 p-1;

    background: linear-gradient(to bottom, rgb(255 255 255 / 10%) 0%, rgb(255 255 255/6%) 20%, transparent 100%),
      linear-gradient(to bottom, rgb(208 208 214 / 10%) 0%, rgb(208 208 214 / 10%) 100%);
    box-shadow:
      0 0 6px 0 rgb(0 0 0 / 21%),
      0 6px 6px 0 rgb(0 0 0 / 12%);
  }
}
