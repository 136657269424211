@import url('tailwindcss/base');

/* Use a custom preflight to prevent css specificity collisions with css modules. */
@import url('./preflight.css');
@import url('./base.css');
@import url('tailwindcss/components');
@import url('./components.css');
@import url('tailwindcss/utilities');

/* Use Data Dashboard font for scroll hint text on google maps */
.gm-style-mot {
  @apply font-sans;
}

/* 
 * Google maps has a bug where it shows a blue focus-like border when interacting with the map. 
 * This bug has been marked as `fixed` in the google issue tracker, but as some other users report, it still exists. 
 * The following CSS removes the border from the affected element, making the focus-style virtually invisible. 
 * 
 * @see https://stackoverflow.com/questions/66669173/google-maps-js-api-shows-blue-focus-border
 * @see https://issuetracker.google.com/issues/182937497
 */
.gm-style iframe + div {
  border: none !important;
}

@layer base {
  body {
    /* stylelint-disable-next-line function-no-unknown */
    --swiper-pagination-color: theme(colors.greyblue.5);
  }
}
